* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul.MuiMenu-list {
    max-height: 368px;
}

body
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #4039c6;
}

body .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: #4039c6;
}

body .MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
    background-color: #4039c60a;
}

body .MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover {
    background-color: #eff0fb;
}

body
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #4039c6;
}

body .MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible {
    background-color: #4039c60a;
}

body .MuiTextField-root > div.Mui-disabled {
    background: rgba(0, 0, 0, 0.1);
}

span.red {
    color: red;
}
